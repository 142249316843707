@import './global-variables.scss';

// There is an issue with the rendering of Trade Gothic text where the height of the text is calculated wrong with redundant empty space below the text
.trade-gothic__container {
  position: relative;
  top: 4px;
}

.form-danger {
  background-image: linear-gradient(#ff0000, #ff0000), linear-gradient(#ff0000, #ff0000) !important;
}

.text-no-wrap {
  white-space: nowrap !important;
}

.global-pink-button {
  background: $globalOrangeColor;
  border-color: $globalOrangeColor;
  text-transform: uppercase;
  box-shadow: none;
  font-family: TradeGothicLTPro-BdCn20, Fallback, serif;

  &:hover {
    background-color: #dd444d;
    border-color: #dd444d;
  }

  &:active {
    background-color: #dd444d !important;
    border-color: #dd444d !important;
  }

  &:disabled {
    color: #fff;
    pointer-events: none;
  }
}

html {
  min-height: 100% !important;
  height: 100%;

  body {
    min-height: 100% !important;
    height: 100%;

    #root {
      min-height: 100% !important;
      height: 100%;

      #main-wrapper {
        min-height: 100% !important;
      }
    }
  }
}

.card-header:first-child {
  border-radius: 0;
}

@font-face {
  font-family: 'CircularProBold';
  src: local('CircularProBold'), url('../fonts/CircularPro-Bold.otf');
}

@font-face {
  font-family: 'CircularProBook';
  src: local('CircularProBook'), url('../fonts/CircularPro-Book.otf');
}

@font-face {
  font-family: 'MinionPro';
  src: local('MinionPro'), url('../fonts/MinionPro-Regular.otf');
}

@font-face {
  font-family: 'TradeGothicBold';
  src: local('TradeGothicBold'), url('../fonts/TradeGothicLTStd-BdCn20.otf');
}

@font-face {
  font-family: 'TradeGothic';
  src: local('TradeGothic'), url('../fonts/TradeGothicLTStd-Cn18.otf');
}

@font-face {
  font-family: 'TradeGothicLTPro-BdCn20';
  src: url('../fonts/TradeGothicWebFont/3841BE_0_0.eot');
  src: url('../fonts/TradeGothicWebFont/3841BE_0_0.eot?#iefix') format('embedded-opentype'),
    url('../fonts/TradeGothicWebFont/3841BE_0_0.woff2') format('woff2'),
    url('../fonts/TradeGothicWebFont/3841BE_0_0.woff') format('woff'),
    url('../fonts/TradeGothicWebFont/3841BE_0_0.ttf') format('truetype');
}

body {
  font-family: 'CircularPro', Fallback, sans-serif;
}
