@import '../../assets/scss/_global-variables.scss';

.banner-modal {
  font-family: 'CircularProBold', Fallback, sans-serif;
  .modal-badge {
    width: 100%;
    margin-top: 1em;
  }
  .reset-banner-modal-body {
    font-size: 20px;
    color: #455a64;
  }

  .reset-banner-website-nav {
    font-size: 20px;
    text-align: center;
    color: #8898aa;
    font-family: 'CircularProBook', Fallback, sans-serif;
    strong {
      cursor: pointer;
    }
  }
}

@media only screen and (max-device-width: 767px) {
  .use-app {
    display: none;
  }

  .mobile-banner {
    font-family: 'CircularProBook', Fallback, sans-serif;
    width: 100%;
    display: block;
    margin-top: 58px;
    position: fixed;
    padding-top: 7px;
    z-index: 0;
    text-align: center;
    background-color: #f17179;
    a {
      color: #fff;
      strong {
        font-family: 'CircularProBold', Fallback, sans-serif;
        font-weight: 900;
      }
    }
  }
}

@media only screen and (min-device-width: 768px) {
  .use-app {
    padding: 0px 15px;
    font-size: 19px;
    line-height: 70px;
    height: 70px;
    button,
    a {
      background: $globalOrangeColor;
      border-color: $globalOrangeColor;
      text-transform: uppercase;
      font-size: 16px;
      font-family: TradeGothicLTPro-BdCn20, Fallback, serif;

      &:hover {
        // background-color: #DD444D;
        border-color: $globalOrangeColor;
        background: $globalOrangeColor;
        opacity: 0.9;
      }

      &:active {
        background-color: #dd444d !important;
        border-color: #dd444d !important;
      }
    }
  }

  .mobile-banner {
    display: none;
  }
}
