.custom-nav-link {
  font-size: 16px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: CircularProBold, Fallback, serif;
}

.header-icon {
  width: 31px;
  height: 31px;
}

.active-nav-link {
  border: 0 none;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  float: none;
  background: #f17179;
  color: white;
}

li:hover .active-nav-link {
  opacity: 0.9;
}
