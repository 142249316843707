.custom-grid {
  flex-flow: row wrap;

  .title-card__container {
    flex: 100%;
    max-width: 100%;
  }

  .content-card__container {
    flex: 100%;
    max-width: 100%;
  }

  @media only screen and (min-device-width: 701px) {
    .title-card__container {
      flex: 20%;
      max-width: 20%;
    }

    .content-card__container {
      flex: 80%;
      max-width: 80%;
    }
  }
}